<template>
  <section v-if="distance > 0 && questionIndex != dataQuestion.length">
    <br />
    <br />
    <b-row class="match-height p-1">
      <b-col xl="9" md="6">
        <b-card
          no-body
          class="business-card"
          v-if="questionIndex != dataQuestion.length"
        >
          <b-card-header
            class="pb-1"
            v-if="questionIndex != dataQuestion.length"
          >
            <b-card-title
              >Soal ke {{ questionIndex + 1 }} /
              {{ dataQuestion.length }}</b-card-title
            >
            <b-nav>
              <b-nav-item>
                <b-button variant="primary">
                  <feather-icon icon="ClockIcon" style="color: white" />
                  <span class="align-right"
                    ><b style="color: white">
                      <!-- Waktu:  -->
                      {{ hours }} : {{ minutes }} : {{ seconds }}</b
                    ></span
                  >
                </b-button>
              </b-nav-item>
            </b-nav>
          </b-card-header>
          <div v-for="(soal, id) in dataQuestion" :key="id">
            <b-card-body v-if="id === questionIndex">
              <b-card-text class="space_good text-justify">
                {{ soal.questions.question }}
              </b-card-text>
              <br />
              <h6 class="mb-75">Pilihan Ganda</h6>
              <div class="business-items" v-if="isLoading == 0">
                <!-- class="business-item" -->
                <div
                  style="padding-bottom: 5px"
                  v-for="choice in soal.questions.answers"
                  v-bind:key="choice.id"
                >
                  <template>
                    <div
                      class="d-flex align-items-center justify-content-between"
                    >
                      <b-form-radio
                        :name="'some-radios' + soal.questions.id"
                        :value="choice.id"
                        v-model="soal.questions.results[0].answers_id"
                        @change="
                          choices(
                            choice.id,
                            soal.questions.id,
                            choice.is_correct
                          )
                        "
                      >
                        {{ choice.answer }}
                      </b-form-radio>
                    </div>
                  </template>
                </div>
              </div>
              <div v-else class="text-center">
                <b-img
                  :src="require('@/assets/images/avatars/13-small.png')"
                  style="
                    width: 80px;
                    height: 80px;
                    padding-bottom: 0px;
                    margin-bottom: 0px;
                  "
                  alt="logo"
                ></b-img>
                <p>
                  <b>Tunggu Sebentar ... </b>
                </p>
              </div>

              <b-progress
                v-if="isLoading"
                animated
                :value="progressBar"
                :style="{ width: progressBar + '%' }"
                show-value
                :class="'progress-bar-primary'"
              />
              <p></p>

              <div v-if="questionIndex != dataQuestion.length">
                <b-row>
                  <b-col md="6" xl="4">
                    <b-button
                      variant="warning"
                      class="mb-1"
                      style="width: 100%"
                      v-if="questionIndex > 0"
                      @click="prev()"
                    >
                      <feather-icon icon="ArrowLeftIcon" style="color: white" />
                      <span class="align-middle"
                        ><b style="color: white"> Sebelumnya</b></span
                      >
                    </b-button>
                  </b-col>
                  <b-col md="6" xl="4"> </b-col>
                  <b-col
                    md="6"
                    xl="4"
                    v-if="questionIndex + 1 != dataQuestion.length"
                  >
                    <b-button
                      variant="success"
                      style="width: 100%"
                      @click="next()"
                    >
                      <!-- @click="postUserChoices(questions.id)" -->
                      <span class="cursor-pointer"
                        ><b style="color: white">Selanjutnya </b></span
                      >
                      <feather-icon
                        icon="ArrowRightIcon"
                        style="color: white"
                      />
                    </b-button>
                  </b-col>
                  <b-col md="6" xl="4" v-else>
                    <b-button
                      variant="primary"
                      style="width: 100%"
                      @click="FinishExam()"
                    >
                      <!-- @click="postUserChoices(questions.id)" -->
                      <span class="cursor-pointer"
                        ><b style="color: white">Selesai </b></span
                      >
                      <feather-icon
                        icon="ArrowRightIcon"
                        style="color: white"
                      />
                    </b-button>
                  </b-col>
                </b-row>
                <p></p>

                <b-form-checkbox
                  value="1"
                  type="checkbox"
                  v-model="soal.questions.results[0].cons_answer"
                  :id="'checkbox-group' + soal.questions.id"
                  class="custom-control-warning"
                  @change="
                    optionwarning(
                      soal.questions.id,
                      soal.questions.results[0].cons_answer
                    )
                  "
                >
                  Ragu-Ragu
                </b-form-checkbox>
              </div>
            </b-card-body>
          </div>
        </b-card>
      </b-col>

      <b-col xl="3" md="6" v-if="questionIndex != dataQuestion.length">
        <b-card class="scrollable">
          <b-row cols="5" cols-sm="5" cols-md="4" cols-lg="5">
            <b-col
              style="padding-left: 3px; padding-right: 3px"
              v-for="(answer, index) in dataQuestion"
              v-bind:key="answer.id + 1000"
              class="text-center"
            >
              <b-button
                v-if="questionIndex != index && answer.cons_answer == 1"
                @click="
                  jumpquestion(index, answer.answers_id, answer.cons_answer)
                "
                variant="warning"
                class="text-center"
                style="
                  padding-left: 8px;
                  padding-right: 8px;
                  width: 45px;
                  height: 45px;
                "
              >
                {{ index + 1 }}
              </b-button>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <b-button
                v-else-if="questionIndex != index && answer.answers_id == 0"
                @click="jumpquestion(index)"
                variant="danger"
                class="text-center"
                style="
                  padding-left: 8px;
                  padding-right: 8px;
                  width: 45px;
                  height: 45px;
                "
              >
                {{ index + 1 }}
              </b-button>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <b-button
                v-else-if="
                  questionIndex != index &&
                  answer.answers_id != 0 &&
                  answer.cons_answer == 0
                "
                @click="
                  jumpquestion(index, answer.answers_id, answer.cons_answer)
                "
                variant="success"
                class="text-center"
                style="
                  padding-left: 8px;
                  padding-right: 8px;
                  width: 45px;
                  height: 45px;
                "
              >
                {{ index + 1 }}
              </b-button>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <b-button
                v-else
                @click="
                  jumpquestion(index, answer.answers_id, answer.cons_answer)
                "
                v-bind:key="index"
                variant="info"
                class="text-center"
                style="
                  padding-left: 8px;
                  padding-right: 8px;
                  width: 45px;
                  height: 45px;
                "
              >
                {{ index + 1 }}
              </b-button>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <p></p>
            </b-col>
          </b-row>
        </b-card>
        <b-card
          class="card-transaction"
          no-body
          v-if="questionIndex != dataQuestion.length"
        >
          <b-card-header>
            <b-card-title>Informasi Ujian</b-card-title>
          </b-card-header>

          <b-card-body>
            <div class="transaction-item">
              <b-media no-body>
                <b-media-aside>
                  <b-avatar rounded size="40" variant="light-warning">
                    <feather-icon
                      size="18"
                      icon="AlertCircleIcon"
                      variant="warning"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <p></p>
                  <h6 class="transaction-title">Jumlah</h6>
                </b-media-body>
              </b-media>
              <div class="font-weight-bolder">{{ dataQuestion.length }}</div>
            </div>
            <div class="transaction-item">
              <b-media no-body>
                <b-media-aside>
                  <b-avatar rounded size="40" variant="light-warning">
                    <feather-icon
                      size="18"
                      icon="AlertCircleIcon"
                      variant="warning"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <p></p>
                  <h6 class="transaction-title">Dijawab</h6>
                </b-media-body>
              </b-media>
              <div class="font-weight-bolder">{{ total_answer }}</div>
            </div>
            <div class="transaction-item">
              <b-media no-body>
                <b-media-aside>
                  <b-avatar rounded size="40" variant="light-warning">
                    <feather-icon
                      size="18"
                      icon="AlertCircleIcon"
                      variant="warning"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <p></p>
                  <h6 class="transaction-title">Ragu-Ragu</h6>
                </b-media-body>
              </b-media>
              <div class="font-weight-bolder">{{ total_ragu }}</div>
            </div>
            <div class="transaction-item">
              <b-media no-body>
                <b-media-aside>
                  <b-avatar rounded size="40" variant="light-warning">
                    <feather-icon
                      size="18"
                      icon="AlertCircleIcon"
                      variant="warning"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <p></p>
                  <h6 class="transaction-title">Kosong</h6>
                </b-media-body>
              </b-media>
              <div class="font-weight-bolder">{{ total_empty }}</div>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </section>
  <section v-else-if="distance < 0 && questionIndex != dataQuestion.length">
    <b-card
      class="card-transaction"
      no-body
      v-for="cekStatus in dataStatusPlan"
      v-bind:key="cekStatus.id"
    >
      <b-card-header>
        <b-card-title>Perhatian !</b-card-title>
      </b-card-header>
      <b-card-body v-if="cekStatus.status_plan == 'ulang'">
        <h2 class="text-center text-danger">
          <b>Waktu Ujian Sudah Berakhir !!!</b>
        </h2>
        <h4 class="text-center"><b>Silahkan Ulang atau Cek Hasil Ujian</b></h4>
        <br />
        <br />
        <b-row>
          <b-col md="6" xl="4" class="mb-1" />
          <b-col md="6" xl="2" class="mb-1">
            <b-button
              variant="danger"
              block
              type="button"
              @click="deleteData()"
            >
              <span class="align-middle"
                ><b style="color: white">Ujian Ulang</b></span
              >
            </b-button>
          </b-col>
          <b-col md="6" xl="2" class="mb-1">
            <!-- @click="
                  $router.push({
                    name: 'list_analisa_hasil_tryout_user',
                    params: { id: id_param },
                  })
                " -->
            <b-button
              variant="primary"
              block
              type="button"
              @click="FinishExam()"
            >
              <span class="align-middle"
                ><b style="color: white"> Lihat Hasil</b></span
              >
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
      <b-card-body v-else-if="cekStatus.status_plan == 'sekali'">
        <h2 class="text-center text-danger">
          <b>Waktu Ujian Sudah Berakhir !!!</b>
        </h2>
        <h4 class="text-center"><b>Silahkan Cek Hasil Ujian</b></h4>
        <br />
        <br />
        <b-row>
          <b-col md="6" xl="4" class="mb-1" />
          <!-- @click="
                  $router.push({
                    name: 'list_analisa_hasil_tryout_user',
                    params: { id: id_param },
                  })
                " -->
          <b-col md="6" xl="4" class="mb-1">
            <b-button
              variant="primary"
              block
              type="button"
              @click="FinishExam()"
            >
              <span class="align-middle"
                ><b style="color: white"> Lihat Hasil</b></span
              >
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </section>
</template>

<script>
import {
  BCard,
  BAvatar,
  BImg,
  BCardText,
  BBadge,
  BCardHeader,
  BCardTitle,
  BDropdown,
  BDropdownItem,
  BCardBody,
  BRow,
  BCol,
  BTableSimple,
  BMedia,
  BMediaAside,
  BMediaBody,
  BProgress,
} from "bootstrap-vue";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Swal from "sweetalert2";
export default {
  components: {
    BCard,
    ToastificationContent,
    BImg,
    Swal,
    BCardText,
    BBadge,
    BCardHeader,
    BTableSimple,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardBody,
    BRow,
    BCol,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    AppTimeline,
    AppTimelineItem,
    BProgress,
  },

  data() {
    return {
      id_session: localStorage.getItem("Uid"),
      id_param: this.$route.params.id,
      questionIndex: 0,
      currentQuestion: 0,
      currentAnswer: 0,
      radioGroup: 0,
      dataQuestion: [],
      datacountEmptyExam: 0,
      datacountAnswerExam: 0,
      datacountRaguExam: 0,
      dataviewAnswerExam: [],
      answer: "",
      start: 1,
      end: "",
      split: "",
      cons_answer: 0,
      isLoading: 0,
      answerIndex: 0,
      hours: "00",
      minutes: "00",
      seconds: "00",
      distance: 0,
      progressBar: 0,
      dataStatusPlan: [],
    };
  },
  // async created() {
  //   await this.getExamTime();
  // },
  computed: {
    total_ragu() {
      const sum = 0;
      return this.dataQuestion.reduce(
        (sum, dataQuestion) => sum + parseInt(dataQuestion.cons_answer),
        0
      );
    },
    total_answer() {
      const sum = 0;
      return this.dataQuestion.reduce(
        (sum, dataQuestion) => sum + parseInt(dataQuestion.count_answer),
        0
      );
    },
    total_empty() {
      const sum = 0;
      return parseInt(this.dataQuestion.length) - parseInt(this.total_answer);
    },
  },

  async mounted() {
    this.checkAuth();
    await this.getQuestions();
    this.getExamTime();
  },

  methods: {
    checkAuth() {
      var sessionCheck = localStorage.getItem("Uid");
      if (sessionCheck == null || sessionCheck == "") {
        this.$router.push({ name: "login" });
      }
    },
    async getQuestions() {
      await this.$http
        .get(
          process.env.VUE_APP_BACKEND_URL +
            `questions/exam/` +
            this.id_param +
            `/` +
            this.id_session
        )
        .then((res) => {
          this.dataQuestion = res.data.data;
          // console.log("cek Data", this.dataQuestion);
        });
    },

    jumpquestion(number, answer, cons) {
      this.questionIndex = number;
      this.answerIndex = answer;
      this.cons_answer = cons;
      // console.log(this.questionIndex, this.answerIndex, this.cons_answer);
    },
    prev() {
      this.questionIndex--;
    },
    next() {
      this.questionIndex++;
    },
    optionwarning(question, answer) {
      // (this.currentAnswer = answer), (this.currentQuestion = question);
      // this.currentAnswer = answer;
      // console.log("ini ID answer", answer);
      // console.log("ini ID question", question);
      this.$http
        .post(process.env.VUE_APP_BACKEND_URL + `questions/exam_answeroption`, {
          answerId: answer,
          questionId: question,
          planId: this.id_param,
          sessionId: this.id_session,
        })
        .then((response) => {
          this.getQuestions();
        });
      this.isLoading = 1;
      setTimeout(() => {
        this.isLoading = 0;
      }, 2000);
    },
    choices(answer, question, correct) {
      (this.currentAnswer = answer), (this.currentQuestion = question);
      // this.currentAnswer = answer;
      // console.log("ini ID answer", answer);
      // console.log("ini ID question", question);
      // console.log("ini ID correct", correct);
      // console.log("ini Question", this.getQuestions());
      this.$http
        .post(
          process.env.VUE_APP_BACKEND_URL + `questions/exam_answer`,
          {
            answerId: this.currentAnswer,
            questionId: this.currentQuestion,
            planId: this.id_param,
            sessionId: this.id_session,
            correct: correct,
          },
          {
            onUploadProgress: function (progressEvent) {
              this.progressBar = parseInt(
                Math.round((progressEvent.loaded * 100) / progressEvent.total)
              );
            }.bind(this),
          }
        )
        .then((response) => {
          this.getQuestions();
        });

      this.isLoading = 1;
      setTimeout(() => {
        this.isLoading = 0;
      }, 2000);
    },

    FinishExam() {
      // console.log("Jawab",this.datacountAnswerExam)
      Swal.fire({
        title: "Ujian Selesai !",
        text: "Akhiri dan Cek Hasil Ujian",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya, selesai!",
      }).then((result) => {
        if (result.value) {
          this.$http.post(
            process.env.VUE_APP_BACKEND_URL + `questions/finish_exam`,
            {
              planId: this.id_param,
              sessionId: this.id_session,
            }
          );
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Notification",
                icon: "CheckCircleIcon",
                text: "Ujian Selesai",
                variant: "success",
              },
            },
            {
              timeout: 5000,
            },
            {
              position: "top-right",
            }
          );
          window.location.href =
            process.env.VUE_APP_FRONTEND_URL +
            `user/finish_tryout/` +
            this.id_param;
        } else {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Notification",
                icon: "CheckCircleIcon",
                text: "Batal Akhiri Ujian",
                variant: "warning",
              },
            },
            {
              timeout: 5000,
            },
            {
              position: "top-right",
            }
          );
        }
      });
    },
    getExamTime() {
      var id_session = localStorage.getItem("Uid");
      var id_param = this.$route.params.id;
      this.$http
        .get(
          process.env.VUE_APP_BACKEND_URL +
            `questions/time_exam/${this.id_session}` +
            `/` +
            this.id_param
        )
        .then((response) => {
          this.dataStatusPlan = response.data.data;
          var getDeathTime = response.data.data[0].start;
          this.id_death_time = getDeathTime;
          var timecount = this.id_death_time;
          // var timecount = "Sep 27 2022 08:05:20";
          var vm = this;
          var id_ses = id_session;
          var id_par = id_param;
          // console.log("mikixxx", response.data.data[0].start)
          var x = setInterval(function () {
            var now = new Date().getTime();
            var countDownDate = new Date(timecount).getTime();
            vm.distance = countDownDate - now;
            vm.days = Math.floor(vm.distance / (1000 * 60 * 60 * 24));
            vm.hours = Math.floor(
              (vm.distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
            );
            vm.minutes = Math.floor(
              (vm.distance % (1000 * 60 * 60)) / (1000 * 60)
            );
            vm.seconds = Math.floor((vm.distance % (1000 * 60)) / 1000);

            //----------------------------AUTO-----------------------------------------
            // console.log("mikki", vm.distance);
            if (vm.distance >= 0 && vm.distance <= 8000) {
              // setTimeout(() => {
              window.location.href =
                process.env.VUE_APP_FRONTEND_URL +
                `user/finish_tryout/` +
                id_par;
              // }, 2000);
            }
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async deleteData() {
      await Swal.fire({
        title: "Ingin Ulangi Ujian?",
        text: "Data akan direset kembali?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya, Reset!",
      })
        .then((result) => {
          // console.log("test",id, plans_id, users_id)
          if (result.value) {
            this.$http
              .delete(
                process.env.VUE_APP_BACKEND_URL +
                  `registerplans/delete/${this.id_param}` +
                  `/` +
                  `${this.id_session}`
              )
              .then((result) => {
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: "Notification",
                      icon: "CheckCircleIcon",
                      text: "Reset Ujian",
                      variant: "danger",
                    },
                  },
                  {
                    timeout: 5000,
                  },
                  {
                    position: "top-right",
                  }
                );
              });
            this.$router.push({ name: "list_tryout" });
          } else {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: "Notification",
                  icon: "CheckCircleIcon",
                  text: "Batal Reset Ujian",
                  variant: "warning",
                },
              },
              {
                timeout: 5000,
              },
              {
                position: "top-right",
              }
            );
          }
        })
        .then((result) => {})
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style>
.space_good {
  white-space: pre-wrap;
}

.column_wrapper {
  column-count: 10;
}

.scrollable {
  overflow-y: scroll;
}
</style>

<!-- <template>
  <div>
    <p>{{ currentDateTime }}</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentTime: new Date()
    }
  },
  computed: {
    currentDateTime() {
      let date = this.currentTime.getDate()
      let month = this.currentTime.getMonth() + 1
      let year = this.currentTime.getFullYear()
      let hour = this.currentTime.getHours()
      let minute = this.currentTime.getMinutes()
      let second = this.currentTime.getSeconds()
      
      year = year.toString().slice(-2)
      if (date < 10) {
        date = '0' + date
      }
      if (month < 10) {
        month = '0' + month
      }
      if (hour < 10) {
        hour = '0' + hour
      }
      if (minute < 10) {
        minute = '0' + minute
      }
      if (second < 10) {
        second = '0' + second
      }
      return `${date}-${month}-${year} ${hour}:${minute}:${second}`
    }
  },
  mounted() {
    setInterval(() => {
      this.currentTime = new Date()
    }, 1000)
  }
}
</script> -->

